<template>
  <section>
    <!--工具条-->
    <el-form :inline="true" :model="searchForm" class="toolbar">
      <el-form-item label="手机号">
        <el-input v-model="searchForm.cellphone" width="200"></el-input>
      </el-form-item>
      <el-form-item label="用户名">
        <el-input v-model="searchForm.username" width="200"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="search" type="info">查询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button @click="add" type="success">新增</el-button>
      </el-form-item>
    </el-form>
    <!--列表-->
    <template>
      <el-table :data="gridData" highlight-current-row border v-loading="gridLoading" class="grid">
        <el-table-column prop="username" label="用户名" min-width="120"></el-table-column>
        <el-table-column prop="realname" label="姓名" min-width="100"></el-table-column>
        <el-table-column prop="cellphone" label="手机号" min-width="160"></el-table-column>
        <el-table-column label="角色" min-width="160">
          <template slot-scope="scope">
            <el-tag
              v-for="item in (scope.row.roles.split(','))"
              :key="item"
              type="primary"
              style="margin-right:2px;"
            >{{ item.split('|')[1]}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间" min-width="190">
          <template slot-scope="scope">
            <span>{{getUnixTime(scope.row.createTime)}}</span>
          </template>
        </el-table-column>
        <el-table-column label="状态" min-width="80">
          <template slot-scope="scope">
            <el-tag
              :type="scope.row.status==0? 'success' : 'danger'"
              close-transition
            >{{scope.row.status==0?'有效':'无效'}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" min-width="180">
          <template slot-scope="scope">
            <el-button size="small" @click="edit(scope.row)">编辑</el-button>
            <el-button type="danger" size="small" @click="reset(scope.row)">重置密码</el-button>
          </template>
        </el-table-column>
      </el-table>
    </template>
    <!--分页-->
    <el-pagination
      class="pager"
      @size-change="pageSizeChange"
      @current-change="pageIndexChange"
      :current-page="pageIndex"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="dataTotal"
    ></el-pagination>
    <!--添加-->
    <el-dialog
      title="添加用户"
      height="650"
      :visible.sync="addDialogVisible"
      :close-on-click-modal="false"
    >
      <el-form
        :model="addForm"
        label-position="left"
        label-width="100px"
        :rules="inputRule"
        ref="addForm"
      >
        <el-form-item label="用户名" prop="username">
          <el-input v-model="addForm.username" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="姓名" prop="realname">
          <el-input v-model="addForm.realname" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="cellphone">
          <el-input v-model="addForm.cellphone" auto-complete="off" :maxlength="11"></el-input>
        </el-form-item>
         <el-form-item label="初始密码" prop="password">
          <el-input type="password" v-model="addForm.password" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="description">
          <el-input
            type="textarea"
            v-model="addForm.description"
            auto-complete="off"
            :maxlength="20"
          ></el-input>
        </el-form-item>
        <el-form-item label="角色" prop="roles">
          <el-checkbox-group v-model="addForm.roles">
            <el-checkbox v-for="item in roleList" :key="item.id" :label="item.id">{{item.name}}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <el-switch v-model="addForm.status" active-text="有效" inactive-text="无效"></el-switch>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addFormSubmit" :loading="addFormLoading">提 交</el-button>
      </div>
    </el-dialog>
    <!--修改-->
    <el-dialog title="修改用户" :visible.sync="editDialogVisible" :close-on-click-modal="false">
      <el-form
        :model="editForm"
        label-position="left"
        label-width="100px"
        :rules="inputRule"
        ref="editForm"
      >
        <el-form-item label="用户名" prop="username">
          <el-input v-model="editForm.username" auto-complete="off" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="cellphone">
          <el-input
            v-model="editForm.cellphone"
            auto-complete="off"
            :maxlength="11"
            :disabled="true"
          ></el-input>
        </el-form-item>
        <el-form-item label="姓名" prop="realname">
          <el-input v-model="editForm.realname" auto-complete="off" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="角色" prop="roles">
          <el-checkbox-group v-model="editForm.roles">
            <el-checkbox v-for="item in roleList" :key="item.id" :label="item.id">{{item.name}}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <el-switch v-model="editForm.status" active-text="有效" inactive-text="无效"></el-switch>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editFormSubmit" :loading="editFormLoading">提 交</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="修改密码"
      :close-on-click-modal="false"
      :visible.sync="changePasswordDialogForm"
    >
      <el-form :model="changeForm" :rules="rules" ref="ruleForm" label-width="100px">
        <!-- <el-form-item :label="$t('main.oldPassword') +'：'" prop="oldPassword">
          <el-input type="password" show-password v-model="changeForm.oldPassword"></el-input>
        </el-form-item> -->
        <el-form-item label="新密码：" prop="newPassword">
          <el-input type="password" show-password v-model="changeForm.newPassword"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="repeatPassword">
          <el-input type="password" show-password v-model="changeForm.repeatPassword"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="changePasswordDialogForm = false">{{$t('main.cancel')}}</el-button>
        <el-button type="primary" @click="changeSubmit">{{$t('main.confirm')}}</el-button>
      </div>
    </el-dialog>
  </section>
</template>
<script>
import DataUtil from "../../utils/config/dataUtil";
import api from "../../api/userRole/Manager";
import http from "../../api/home";
export default {
  data() {

    // var validateMobile = (rule, value, callback) => {
    //   if (value != "") {
    //     if (!DataUtil.isMobile(value)) {
    //       callback(new Error("手机号码格式不正确"));
    //     }
    //     callback();
    //   }
    // };
    var validatePass1 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入原密码"));
      } else {
        if (this.changeForm.oldPassword !== "") {
          callback();
        }
      }
    };
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入新密码"));
      } else {
        if (this.changeForm.newPassword !== "") {
          callback();
        }
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.changeForm.newPassword) {
        callback(new Error("两次输入密码不一致"));
      } else {
        callback();
      }
    };
    return {
      roleList: [],

      // 搜索
      searchForm: {
        username: "",
        cellphone: ""
      },

      // Grid
      gridLoading: false,
      gridData: [],
      pageIndex: 1,
      pageSize: 10,
      dataTotal: 0,

      // Form
      addForm: {
        username: "",
        realname: "",
        cellphone: "",
        description: "",
        roles: [],
        password: "",
        status: true
      },
      editForm: {
        id: "",
        username: "",
        realname: "",
        cellphone: "",
        description: "",
        roles: [],
        status: true
      },
      inputRule: {
        username: [
          {
            required: true,
            message: "请输入用户名",
            trigger: "blur"
          }
        ],
        realname: [
          {
            required: true,
            message: "请输入姓名",
            trigger: "blur"
          }
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur"
          }
        ],
        cellphone: [
          {
            required: true,
            message: "请输入手机号",
            trigger: "blur"
          }
        ],
        roles: [
          {
            type: "array",
            required: true,
            message: "请至少选择一个角色",
            trigger: "change"
          }
        ],
        description: [
          {
            required: true,
            message: "请输入备注",
            trigger: "blur"
          }
        ],
      },
      rules: {
        newPassword: [{ validator: validatePass, trigger: "blur" }],
        repeatPassword: [{ validator: validatePass2, trigger: "blur" }]
      },

      // Dialog
      addDialogVisible: false,
      editDialogVisible: false,
      addFormLoading: false,
      editFormLoading: false,

      // 修改密码
      changePasswordDialogForm: false,
      changeForm: {
        oldPassword: "",
        newPassword: "",
        repeatPassword: ""
      },
      userId: "",
    };
  },
  methods: {
    getUnixTime(time) {
      return DataUtil.dateTransform(time);
    },
    add(row) {
      this.addDialogVisible = true;
      this.addFormLoading = false;
      this.$refs.addForm && this.$refs.addForm.resetFields();
      this.addForm.roles = [];
    },
    edit(row) {
      this.editFormLoading = false;
      this.editDialogVisible = true;
      this.editForm.id = row.id;
      this.editForm.username = row.username;
      this.editForm.realname = row.realname;
      this.editForm.cellphone = row.cellphone;
      this.editForm.roles = this.getRoleIds(row.roles.split(","));
      this.editForm.status = row.status == 1 ? false : true;
    },
    reset(row) {
      this.changePasswordDialogForm = true;
      this.userId = row.id;
      this.$refs.changeForm && this.$refs.changeForm.resetFields();
    },
    changeSubmit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          let _data = {
            content: {
              id: this.userId,
              newPassword: this.changeForm.newPassword,
              verPassword: this.changeForm.newPassword
            }
          };
          api.resetPassword(_data).then(res => {
            if (res.code == "0000") {
              this.$refs.ruleForm.resetFields();
              this.changePasswordDialogForm = false;
              this.$message({
                message: "密码重置成功,请重新登录",
                type: "success",
              });
            } else {
              this.$message.error(res.body.message);
            }
          });
        }
      });
    },
    getRoleIds(list) {
      var _arr = [];
      for (let i = 0; i < list.length; i++) {
        _arr.push(Number(list[i].split("|")[0]));
      }
      return _arr;
    },
    pageSizeChange(val) {
      this.pageSize = val;
      this.pageIndex = 1;
      this.bindGrid();
    },
    pageIndexChange(val) {
      this.pageIndex = val;
      this.bindGrid();
    },
    addFormSubmit() {
      this.$refs.addForm.validate(valid => {
        if (valid) {
          let content = Object.assign({}, this.addForm);
          let _data = {};
          _data.content = content;
          content.status = content.status ? "0" : "1";
          content.roles = content.roles.join(",");
          if (content.roles == 0) {
            this.$message({
              message: "至少选择一个角色",
              type: "error"
            });
            return false;
          }

          this.addFormLoading = true;
          api.addUser(_data).then(res => {
            this.addFormLoading = false;
            if (res.code == "0000") {
              this.addDialogVisible = false;
              this.bindGrid();
              this.$message({
                message: "添加成功",
                type: "success"
              });
            } else {
              this.$message.error(res.message);
            }
          });
        } else {
          return false;
        }
      });
    },
    editFormSubmit() {
      this.$refs.editForm.validate(valid => {
        if (valid) {
          let content = Object.assign({}, this.editForm);
          let _data = {};
          _data.content = content;
          content.status = content.status ? "0" : "1";
          content.roles = content.roles.join(",");
          if (content.roles == 0) {
            this.$message({
              message: "至少选择一个角色",
              type: "error"
            });
            return false;
          }
          this.editFormLoading = true;
          api.editUser(_data).then(res => {
            this.editFormLoading = true;
            if (res.code == "0000") {
              this.editDialogVisible = false;
              this.bindGrid();
              this.$message({
                message: "修改成功",
                type: "success"
              });
            } else {
              this.$message.error(res.message);
            }
          });
        } else {
          return false;
        }
      });
    },
    search() {
      this.pageIndex = 1;
      this.bindGrid();
    },
    bindRole() {
      let _data = {};
      api.searchRule(_data).then(res => {
        if (res.code == "0000") {
          this.roleList = res.data.rows;
        }
      });
    },
    bindGrid() {
      this.gridLoading = true;
      let content = Object.assign({}, this.searchForm);
      let _data = {};
      _data.content = content;
      content.pageNo = this.pageIndex;
      content.pageSize = this.pageSize;
      api.bindGrid(_data).then(res => {
        this.gridLoading = false;
        if (res.code == "0000") {
          this.dataTotal = res.data.total;
          this.gridData = res.data.rows;
        } else {
          this.$message.error(res.message);
        }
      });
    }
  },
  created: function() {
    this.bindRole();
    this.bindGrid();
  }
};
</script>
