import request from "@/utils/http/requset";

// 重置密码
function resetPassword(data) {
  return request({
    url: "/manage/manager/reset",
    method: "post",
    data: data,
    dataType: "jsonp"
  });
}

// 添加用户
function addUser(data) {
  return request({
    url: "/manage/manager/create",
    method: "post",
    data: data,
    dataType: "jsonp"
  });
}

// 修改用户
function editUser(data) {
  return request({
    url: "/manage/manager/update",
    method: "post",
    data: data,
    dataType: "jsonp"
  });
}

// 查询角色
function searchRule(data) {
  return request({
    url: "/manage/role/roles",
    method: "post",
    data: data,
    dataType: "jsonp"
  });
}

// 查询列表
function bindGrid(data) {
  return request({
    url: "/manage/manager/search",
    method: "post",
    data: data,
    dataType: "jsonp"
  });
}
export default {
  resetPassword,
  addUser,
  editUser,
  searchRule,
  bindGrid
};
